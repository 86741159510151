import React, { lazy } from "react";
import ReactDOM from "react-dom";
import { MsalProvider } from "@azure/msal-react";

import reportWebVitals from "./reportWebVitals";
import publicClientApplication from "./services/PublicClientApp";
import { EventType } from "@azure/msal-browser";

import "./index.scss";
const App = lazy(() => import("./App"));

publicClientApplication.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !publicClientApplication.getActiveAccount() &&
    publicClientApplication.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    publicClientApplication.setActiveAccount(
      publicClientApplication.getAllAccounts()[0],
    );
  }

  // Optional - This will update account state if a user signs in from another tab or window
  publicClientApplication.enableAccountStorageEvents();

  publicClientApplication.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      publicClientApplication.setActiveAccount(account);
    }
  });

  ReactDOM.render(
    <React.StrictMode>
      <React.Suspense fallback="loading...">
        <MsalProvider instance={publicClientApplication}>
          <App />
        </MsalProvider>
      </React.Suspense>
    </React.StrictMode>,
    document.getElementById("root"),
  );
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
